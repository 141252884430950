<template>
  <div class="container">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h1>Trees list</h1>
      <button :disabled="!mintTrees.length" v-b-modal.mint-modal type="button" class="btn btn-primary">Mint
        {{ mintTrees.length ? mintTrees.length : '' }}
      </button>
    </div>
    <b-modal id="mint-modal" hide-footer>
      <template #modal-title>
        <h3>Trees to mint</h3>
      </template>
      <div>
        <table class="table table-striped table-bordered w-100">
          <thead>
          <tr>
            <th>Forest</th>
            <th>Type</th>
            <th>Quantity</th>
          </tr>
          </thead>
          <tbody>
          <tr :key="tree.id" v-for="tree in groupedTrees">
            <td>{{ tree.forest === 'null' ? '' : tree.forest }}</td>
            <td>{{ tree.treeType === 'null' ? '' : tree.treeType }}</td>
            <td>{{ tree.quantity === 'null' ? '' : tree.quantity }}</td>
          </tr>
          </tbody>
        </table>

        <div class="row">
          <div class="col-12">
            <label for="customer">Customer</label>
            <b-form-select id="customer" v-model="selectedCustomer" :options="modalSelectorOptions"></b-form-select>
          </div>

          <div class="col-12">
            <label for="wallet-address">Wallet address</label>
            <b-form-input v-model="selectedCustomer"></b-form-input>
          </div>
        </div>
      </div>
      <div class="mt-3 d-flex justify-content-center">
        <b-button @click="mint">Mint</b-button>
      </div>
    </b-modal>
    <table id="trees-datatable" class="table table-striped table-bordered w-100"></table>
  </div>
</template>

<script>
import "datatables.net/js/jquery.dataTables";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-fixedheader-bs4/js/fixedHeader.bootstrap4";
import "datatables.net-responsive-bs4/js/responsive.bootstrap4";
import "datatables.net-scroller-bs4/js/scroller.bootstrap4";
import $ from 'jquery';
import Header from "@/utils/Header";
import TokenStorage from "@/utils/TokenStorage";
import {ethers} from "ethers";
// import Swal from "sweetalert2";
import contractAddresses from '../contracts/addresses.json';
import contractAbi from '../contracts/MintTreesContractAbi.json';
import Trees from "../api/Trees";

export default {
  data() {
    return {
      name: 'trees view',
      treesDatatable: null,
      mintTrees: [],
      selectedCustomer: null,
      users: [],
      contract: null,
      provider: null,
      signer: null,
    }
  },
  computed: {
    groupedTrees() {
      const group = {};
      const groupedTrees = [];
      for (const mintTree of this.mintTrees) {
        let forestGroup = group[mintTree.forest];
        if (!forestGroup) {
          forestGroup = {};
        }
        let typeCounter = forestGroup[mintTree.tree_type];
        if (!typeCounter) {
          typeCounter = 0;
        }
        typeCounter++;
        forestGroup[mintTree.tree_type] = typeCounter;
        group[mintTree.forest] = forestGroup;
      }

      for (const [forest, treeTypeGroup] of Object.entries(group)) {
        for (const [treeType, quantity] of (Object.entries(treeTypeGroup))) {
          groupedTrees.push({forest, treeType, quantity});
        }
      }

      return groupedTrees;
    },
    modalSelectorOptions() {
      return [
        {value: null, text: 'Select a customer'},
        ...this.users
      ]
    }
  },
  methods: {
    async mint() {
      if (this.mintTrees.length === 0) {
        return;
      }

      if (!this.provider || !this.signer) {
        const provider = await this.connectEthers();
        await provider.send('eth_requestAccounts', []);
        const signer = provider.getSigner();
        this.provider = provider;
        this.signer = signer;
        this.contract = new ethers.Contract(contractAddresses.MyTreeInitiative.Mint, contractAbi, this.signer);
      }

      const transaction = await this.contract.mint(this.selectedCustomer, this.mintTrees.length);
      const transactionResponse = await transaction.wait();
      const NFTs = transactionResponse.events.map(event => event.args.tokenId.toNumber());
      const apiPayload = [];
      for (let i = 0; i < NFTs.length; i++) {
        const tree = this.mintTrees[i];
        const NFT = NFTs[i];

        apiPayload.push({tree: tree.id, NFT});
      }
      await Trees.setNFTs(apiPayload);
    },
    addTree(row) {
      const includes = !!this.mintTrees.find(tree => tree.id === row.id);
      if (!includes) {
        this.mintTrees.push(row);
      }
    },
    removeTree(row) {
      this.mintTrees = this.mintTrees.filter(tree => tree.id !== row.id);
    },
    checkColumnCheckbox() {
      let allChecked = true;

      this.treesDatatable.data().toArray().forEach(row => {
        if (!this.mintTrees.find(tree => tree.id === row.id)) {
          allChecked = false;
        }
      })

      $('.tree-column-checkbox').prop('checked', allChecked);
    },
    async getUsers() {
      const response = await fetch(`${process.env.VUE_APP_API_URL}/customers`, {
        headers: {
          'Authorization': Header.getTokenValue(TokenStorage.get())
        }
      });

      return response.json();
    },
    async fillUsers() {
      const users = await this.getUsers();
      this.users = users.map(user => ({value: user.walletAddress, text: user.username}));
    },
    async connectEthers() {
      // const GnosisChainId = 100 // 0x64
      if (!window.ethereum) {
        throw new Error('No ethereum provider');
      }
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      // const network = await provider.getNetwork();
      // Changing to gnosis
      // if (network.chainId !== GnosisChainId) {
      //   const response = await Swal.fire({
      //     title: 'Wrong chain',
      //     html: 'The required chain is Gnosis',
      //     showCancelButton: true,
      //     confirmButtonText: 'Change chain',
      //     cancelButtonText: `Close`,
      //   });
      //   if (!response.isConfirmed) {
      //     throw new Error('invalid chain')
      //   }
      //   await this.changeToGnosis();
      // }
      provider = new ethers.providers.Web3Provider(window.ethereum);
      return provider;
    },
    async changeToGnosis() {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0x64',
          chainName: 'Gnosis',
          rpcUrls: [
            'https://gnosis.api.onfinality.io/public',
          ],
          nativeCurrency: {
            name: 'XDAI',
            symbol: 'XDAI',
            decimals: 18
          }
        }]
      })
    }
  },

  mounted() {
    this.fillUsers();
    let self = this;

    this.treesDatatable = $('#trees-datatable').DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      search: true,
      pagination: true,
      totalRows: 1,
      currentPage: 1,
      ajax: {
        url: process.env.VUE_APP_API_URL + '/trees/datatable',
        type: 'POST',
        headers: {
          "Authorization": Header.getTokenValue(TokenStorage.get())
        }
      },
      columns: [
        {
          width: 40,
          title: '<input class="form-check-input tree-column-checkbox" type="checkbox"> Select',
          data: 'id',
          defaultContent: '',
          orderable: false
        },
        {
          title: 'Forest',
          data: 'forest',
          defaultContent: ''
        },
        {
          title: 'Geo',
          data: 'geo',
          defaultContent: ''
        },
        {
          title: 'Country',
          data: 'country',
          defaultContent: ''
        },
        {
          title: 'Tree type',
          data: 'tree_type',
          defaultContent: ''
        },
      ],
      columnDefs: [
        {
          targets: 0,
          render(id) {
            return `<input data-id="${id}" class="form-check-input tree-checkbox" ${self.mintTrees.map(row => row.id).includes(id) ? 'checked' : ''} type="checkbox">`
          }
        },
        {
          targets: 2,
          render(data) {
            const [latitude, longitude] = data.split(',');
            return `<a target="_blank" href="https://www.google.com/maps/search/?api=1&query=${longitude},${latitude}">See in location <i class="fa-solid fa-location-dot"></i></a>`
          }
        }
      ],
      drawCallback: function(settings) {
        let selector = $(this).closest('.dataTables_wrapper').find('.dataTables_length').find('select');
        let inputField = $('<input type="number" class="form-control form-control-sm" style="width: 70px; display: inline">')
          .val(settings._iDisplayLength)
          .on('change', function() {
            let val = $(this).val();
            if (val && val > 0) {
              let table = $(this).closest('.dataTables_wrapper').find('.dataTable').DataTable();
              table.page.len(val).draw();
            }
          });
        selector.replaceWith(inputField);
      }
    });
    $(document).on('click', '.tree-checkbox', function () {
      const id = $(this).data('id');
      if (id) {
        const row = self.treesDatatable.data().toArray().find(dataRow => dataRow.id === id);
        if (this.checked) {
          self.addTree(row);
        } else {
          self.removeTree(row);
        }
        self.checkColumnCheckbox();
      }
    })

    $(document).on('click', '.tree-column-checkbox', function () {
      if (this.checked) {
        self.treesDatatable.data().toArray().forEach(tree => self.addTree(tree));
        $('.tree-checkbox').prop('checked', true);
      }
      if (!this.checked) {
        self.treesDatatable.data().toArray().forEach(tree => self.removeTree(tree));
        $('.tree-checkbox').prop('checked', false);
      }
    })

    this.treesDatatable.on('draw', function () {
      self.checkColumnCheckbox();
    })
  }
}
</script>

<style scoped>

</style>
